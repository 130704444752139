import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import "./Videos.css"
import './main.css'


export default function Videos(){
    const [videos, setVideos] = useState<string[]>([]);
    
    useEffect(() => {
        async function getVideoLinks(){
            
            
            try{
                let videoLinksJson = await fetch(`${process.env.PUBLIC_URL}/videos.txt`); 
                let videoLinks = await videoLinksJson.json();
                let links = videoLinks["videos"]
                setVideos(links)
                console.log(links)
            }catch{

            }

        }
        getVideoLinks();
    }, [])


    const pageBody = (
        <div className="videos">
            <h1 className="videos_section_title">Latest Videos</h1>

            <Container>
                {videos.map(video => {
                    const url = `https://www.youtube.com/embed/${video}`;
                    return (
                        <div className="ratio ratio-16x9 yt_video">
                            <iframe src={url} title="YouTube video" allowFullScreen></iframe>
                        </div>
                    )
                })}
            </Container>
        </div>
    );
    
    return (
        <div>
        { videos.length > 0 && {...pageBody}}
        </div>
        )
}