import React from 'react';
import Videos from './Videos';
import './main.css'
import NavBar from './NavBar';
import Socials from './Socials';

export default function HomePage(){

    return (
        <>
        <div className="image_background" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/rubber_duck.jpg")`}}>
            <NavBar/>
            <div className="main">
                <div className="body_contents">
                    <h1 className="title">Connies</h1>
                    <h1 className="title">BIG DUCK</h1>
                    <h1 className="title">Bathrooms</h1>
                    <p className="phone">Phone: 07789795178</p>
                    {/* <Socials/> */}
                </div>
            </div>
        </div>
    
        <Videos/>
        </>
    )
}