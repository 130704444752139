import React from "react"
import './NavBar.css'

export default function NavBar(){
    return (
        <div className="navbar_container">
            <p className="navbar_title">Connies BIG DUCK Bathrooms</p>
        </div>
    )
}

